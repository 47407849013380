/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_18582_lbexh_145:not(#\9) {
  display: flex;
}

.awsui_child_18582_lbexh_149:not(#\9) {
  /* used in test-utils */
}

.awsui_child_18582_lbexh_149:not(#\9):empty {
  display: none;
}

/*
 * Horizontal variant
 */
.awsui_horizontal_18582_lbexh_160:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_horizontal-xxxs_18582_lbexh_164:not(#\9) {
  gap: var(--space-xxxs-z28114, 2px);
}
.awsui_horizontal-xxs_18582_lbexh_167:not(#\9) {
  gap: var(--space-xxs-9rrxti, 4px);
}
.awsui_horizontal-xs_18582_lbexh_170:not(#\9) {
  gap: var(--space-xs-xf5ch3, 8px);
}
.awsui_horizontal-s_18582_lbexh_173:not(#\9) {
  gap: var(--space-s-2025hl, 12px);
}
.awsui_horizontal-m_18582_lbexh_176:not(#\9) {
  gap: var(--space-m-kpg8id, 16px);
}
.awsui_horizontal-l_18582_lbexh_179:not(#\9) {
  gap: var(--space-l-xu1x80, 20px);
}
.awsui_horizontal-xl_18582_lbexh_182:not(#\9) {
  gap: var(--space-xl-5fdwzu, 24px);
}
.awsui_horizontal-xxl_18582_lbexh_185:not(#\9) {
  gap: var(--space-xxl-pd3205, 32px);
}

/*
 * Vertical variant
 */
.awsui_vertical_18582_lbexh_192:not(#\9) {
  flex-direction: column;
}
.awsui_vertical-xxxs_18582_lbexh_195:not(#\9) {
  row-gap: var(--space-xxxs-z28114, 2px);
}
.awsui_vertical-xxs_18582_lbexh_198:not(#\9) {
  row-gap: var(--space-xxs-9rrxti, 4px);
}
.awsui_vertical-xs_18582_lbexh_201:not(#\9) {
  row-gap: var(--space-xs-xf5ch3, 8px);
}
.awsui_vertical-s_18582_lbexh_204:not(#\9) {
  row-gap: var(--space-scaled-s-z0v7is, 12px);
}
.awsui_vertical-m_18582_lbexh_207:not(#\9) {
  row-gap: var(--space-scaled-m-fbpbtw, 16px);
}
.awsui_vertical-l_18582_lbexh_210:not(#\9) {
  row-gap: var(--space-scaled-l-coydx8, 20px);
}
.awsui_vertical-xl_18582_lbexh_213:not(#\9) {
  row-gap: var(--space-scaled-xl-5xk2j7, 24px);
}
.awsui_vertical-xxl_18582_lbexh_216:not(#\9) {
  row-gap: var(--space-scaled-xxl-4l9f8c, 32px);
}

.awsui_align-center_18582_lbexh_220:not(#\9) {
  align-items: center;
}

.awsui_align-start_18582_lbexh_224:not(#\9) {
  align-items: start;
}

.awsui_align-end_18582_lbexh_228:not(#\9) {
  align-items: end;
}